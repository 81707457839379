











import {Component, Vue} from 'vue-property-decorator';
import SalesAnalysis from '@/views/customer/insight/SalesAnalysis.vue';
import ProductSales from '@/views/customer/insight/ProductSales.vue';
import SalesOrder from '@/views/customer/insight/OrderStatus.vue';
import TaskSummary from '@/views/customer/insight/TaskSummary.vue';
import GuaranteeStatus from '@/views/customer/insight/GuaranteeStatus.vue';
import {OrderService} from "@/views/order/OrderService";
import {ReportByCustomerId} from "@/models/order/ReportByCustomerId";
import {ChartData} from "@/models/ChartData";
import find from 'lodash/find';
import each from 'lodash/each';
import OrderStatus from "@/views/customer/insight/OrderStatus.vue";

@Component({
    components: {
        OrderStatus,
        SalesAnalysis,
        ProductSales,
        SalesOrder,
        TaskSummary,
        GuaranteeStatus
    }
})
export default class CustomerInsight extends Vue {
    salesByYears: ChartData[] = [];
    salesByMonth: ChartData[] = [];
    salesByStatus: ChartData[] = [];
    salesByGroupProduct: ChartData[] = [];

    private orderService = new OrderService();

    constructor() {
        super();
    }

    async mounted() {
        await this.getData();
    }

    private async getData() {
        const customerId = this.$route.params.id;
        // this.$vs.loading()
        this.orderService.reportByCustomer(customerId)
            // .finally(() => {
            //     this.$vs.loading.close();
            // })
            .then((result: ReportByCustomerId) => {
                this.rendererData(result);
            })
    }

    private rendererData(item: ReportByCustomerId) {
        // theo năm
        const dateNow = new Date();
        this.salesByYears = [];
        for (let i = dateNow.getFullYear() - 3; i <= dateNow.getFullYear(); i++) {
            const dataByYear = find(item.years.data, (data: any) => {
                return data.key === i
            });

            if (dataByYear) {
                this.salesByYears.push(new ChartData(`Năm ${i}`, dataByYear.summary.length > 0 ? dataByYear.summary[0] : 0))
            } else {
                this.salesByYears.push(new ChartData(`Năm ${i}`, 0))
            }
        }

        /// theo tháng
        this.salesByMonth = [];
        const monthThreeMonth = dateNow.setMonth(dateNow.getMonth() - 3);
        const startMonth = new Date(monthThreeMonth).getMonth();

        for (let i = 1; i <= 4; i++) {
            let month = startMonth + i;
            if (month > 12) {
                month = month - 12;
            }

            const dataByMonth = find(item.months.data, (data: any) => {
                return data.key === month
            });

            if (dataByMonth) {
                this.salesByMonth.push(new ChartData(`Tháng ${month}`, dataByMonth.summary.length > 0 ? dataByMonth.summary[0] : 0))
            } else {
                this.salesByMonth.push(new ChartData(`Tháng ${month}`, 0))
            }
        }

        // theo nhóm sản phẩm
        this.salesByGroupProduct = [];
        each(item.groupProducts.data, (groupProduct: any) => {
            this.salesByGroupProduct.push(new ChartData(groupProduct.items.length > 0 ? groupProduct.items[0].key : '',
                groupProduct.summary.length > 0 ? groupProduct.summary[0] : 0))
        })

        // theo nhóm trạng thái
        this.salesByStatus = [];
        each(item.status.data, (sale: any) => {
            const summary = sale.summary.length > 0 ? sale.summary[0] : 0;
            this.salesByStatus.push(new ChartData(sale.key, summary, '', sale.count));
        })
    }
}
