














import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import {ChartData} from "@/models/ChartData";
import map from 'lodash/map';
import {formatNumber} from "@/common";

@Component({
    components: {VueApexCharts}
})

export default class ProjectSales extends Vue {
    @Prop({default: () => []}) data: ChartData[];
    chartData: ChartData[] = [];
    pieChart: any = {};
    chartOptions: any = {};
    themeColors = ['#1abc9c','#95a5a6', '#e74c3c', '#3498db',
        '#9b59b6', '#34495e',
        '#f1c40f', '#e67e22', '#2ecc71', '#ecf0f1',
        '#16a085', '#d35400', '#2980b9', '#7f8c8d',
        '#8e44ad', '#2c3e50',
        '#f39c12', '#27ae60', '#c0392b', '#bdc3c7',

    ];

    constructor() {
        super();
    }

    @Watch('data')
    getData(value: ChartData[]) {
        this.chartData = value;
        this.renderChart();
    }

    renderChart() {
        this.chartOptions = {
            labels: map(this.chartData, (item: ChartData) => {
                return item.name ? item.name : 'Nhóm sản phẩm'
            }),
            legend: {
                position: 'bottom',
            },
            colors: this.themeColors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom',
                        horizontalAlign: 'center'
                    }
                }
            }],
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return formatNumber(val) + ' vnđ';
                    }
                }
            }
        };
        this.pieChart = {
            series: map(this.chartData, (item: ChartData) => {
                return item.value
            }),
            chartOptions: this.chartOptions
        }
    }
}
