














import {ChartData} from "@/models/ChartData";
import map from 'lodash/map';

const themeColors = ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'];
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import {formatNumber} from "@/common";

@Component({
    components: {
        VueApexCharts
    }
})
export default class SalesAnalysis extends Vue {
    @Prop({default: ''}) title!: string;
    @Prop({default: () => []}) data: ChartData[];

    chartData: ChartData[] = [];
    chartOptions: any = {};
    columnChart: any = {};

    @Watch('data')
    getData(value: ChartData[]) {
        this.chartData = value;

        this.renderChart();
    }

    renderChart() {
        this.chartOptions = {
            colors: themeColors,
            plotOptions: {
                bar: {
                    horizontal: false,
                    endingShape: 'rounded',
                    columnWidth: '55%'
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },

            xaxis: {
                categories: map(this.chartData, (item: ChartData) => {
                    return item.name
                })
            },
            yaxis: {
                title: {
                    text: ' (vnđ)'
                }
            },
            fill: {
                opacity: 1

            },
            tooltip: {
                y: {
                    formatter: function (val: any) {
                        return formatNumber(val) + ' vnđ';
                    }
                }
            }
        };

        this.columnChart = {
            series: [{
                name: 'Doanh thu',
                data: map(this.chartData, (item: ChartData) => {
                    return item.value
                })
            }],
            chartOptions: this.chartOptions
        };
    }
}
