














import { Component, Vue } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';

@Component({
    components: {
        VueApexCharts
    }
})
export default class TaskSummary extends Vue {
    themeColors = ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'];
    donutChart = {
        series: [44, 55, 41, 17],
        chartOptions: {
            colors: this.themeColors,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 200
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }]
        }
    };
}
