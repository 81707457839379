export class ChartData {
    name: any;
    value: any;
    color: string;
    count: number;

    constructor(name: string = '', value: any = '', color: string = '', count: number = 0) {
        this.name = name;
        this.value = value;
        this.color = color;
        this.count = count;
    }
}