

























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';
import {ChartData} from '@/models/ChartData';
import each from 'lodash/each';
import map from 'lodash/map';
import sum from 'lodash/sum';
import sortBy from 'lodash/sortBy';
import {formatNumber} from "@/common";
import {OptionOrderStatus} from "@/models/order/OrderSearchResult";

@Component({
    components: {
        VueApexCharts
    }
})

export default class OrderStatus extends Vue {
    @Prop({default: () => []}) data: ChartData[];
    themeColors = ['#7367F0', '#28C76F', '#EA5455', '#FF9F43',
        '#1E1E1E'];
    chartData: ChartData[] = [];
    radialBarChart: any = {};
    chartOptions: any = {};

    @Watch('data')
    getData(value: ChartData[]) {
        this.chartData = sortBy(value, (item: ChartData) => {
            return item.name;
        });

        this.renderColor();
        this.renderChart();
    }

    renderChart() {
        const listData: number[] = map(this.chartData, (item: ChartData) => {
            return item.value
        })

        let sumData = sum(listData);
        const series: number[] = [];
        each(listData, (item: number) => {
            series.push(Math.round((item / sumData) * 100));
        });

        this.chartOptions = {
            colors: this.themeColors,
            plotOptions: {
                radialBar: {
                    dataLabels: {
                        name: {
                            fontSize: '22px'
                        },
                        value: {
                            fontSize: '16px',
                        },
                        legend: {
                            position: 'bottom'
                        },
                        total: {
                            show: true,
                            label: 'Tổng số',
                            formatter: function (w: any) {
                                return formatNumber(sumData) + ' đ';
                            }
                        }
                    }
                }
            },
            labels: map(this.chartData, (item: ChartData) => {
                return new OptionOrderStatus(item.name).name
            })
        };

        this.radialBarChart = {
            series: series,
            chartOptions: this.chartOptions
        };
    }

    renderColor() {
        let themColor: any[] = [];
        each(this.chartData, (item: ChartData) => {
            item.color = new OptionOrderStatus(item.name).color;
            themColor.push(new OptionOrderStatus(item.name).colorDescription);
        })

        this.themeColors = themColor;
    }

    renderName(value: any) {
        return new OptionOrderStatus(value).name;
    }

    formatNumber(value: number) {
        return formatNumber(value) + ' đ';
    }
}
